<template>
    <section class="overflow-x-auto">
        <div class="box mb-2 d-flex flex-column justify-content-between">
            <p class="m-0">
                <i class="fas fa-exclamation-triangle mr-1" />Pour acheter un site, vous devez avoir obligatoirement un
                compte sur internet.bs, afin de vous faire le transfert du NDD.
            </p>
        </div>
        <div class="box">
            <SellingWebsiteTable />
        </div>
    </section>
</template>

<script>
    export default {
        title: 'layout.selling-websites',
        name: 'WebsiteSelling'
    }
</script>

<style></style>
